import React from "react";
import { ReviewData } from "./reviewsConfig";
import { Link } from "gatsby";

function UsersReviews({ containerStyling, customStyling, reviewsData }) {
  return (
    <div className={`${containerStyling ? containerStyling : ""}`}>
      {/* <ul
        className={`${
          customStyling
            ? customStyling
            : "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-5 lg:gap-y-8 max-w-lg md:max-w-4xl mx-auto justify-items-center"
        } `}
      > */}
      {/* <ul
        className={`${
          customStyling
            ? customStyling
            : "flex gap-4 flex-wrap gap-y-5 lg:gap-y-8 max-w-7xl mx-auto lg:justify-between justify-center items-center"
        } `}
      >
        {ReviewData &&
          ReviewData.map(({ link, img, alt }, i) => {
            return (
              <li key={i}>
                <Link to={link}>
                  <img src={img} alt={alt} className=" w-72 bg-white" />
                </Link>
              </li>
            );
          })}
      </ul> */}
      <ul className="grid md:grid-cols-2 lg:grid-cols-4 gap-2 place-content-center  max-w-7xl mx-auto">
        {ReviewData &&
          ReviewData.map(({ link, img, alt }, i) => {
            return (
              <li key={i} className="flex items-center justify-center py-4">
                {alt === "google reviews USA" ||
                alt === "trustpilot reviews" ? (
                  <Link to={link} target="_blank">
                    <img src={img} alt={alt} className="bg-white" />
                  </Link>
                ) : (
                  <img src={img} alt={alt} className="bg-white" />
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default UsersReviews;
